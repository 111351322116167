import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import GalleryNavbar from "components/Navbars/GalleryNavbar";
import GalleryHeader from "components/Headers/GalleryHeader.js";
import DemoFooter from "components/Footers/FooterMaster";
import InstaGallery from "views/prod/InstaGallery";
function GalleryPage() {
    const [activeTab, setActiveTab] = React.useState("1");
  
    const toggle = (tab) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    };
  
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("landing-page");
      return function cleanup() {
        document.body.classList.remove("landing-page");
      };
    });
    return (
      <>
        <GalleryNavbar />
        <GalleryHeader />
        <div className="section">
            <div className="row" style={{marginLeft:"150px",marginBottom:"25px"}}>
              <div className="col-md-4">
                <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_0926.JPG").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
              </div>
              <div className="col-md-4">
                <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_1106.jpg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
                </div>
                <div className="col-md-4">
                  <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_1132.jpg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
                </div>
            </div>
            <div className="row" style={{marginLeft:"150px",marginBottom:"25px"}}>
              <div className="col-md-4">
                <img    
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_1425.jpg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
              </div>
              <div className="col-md-4">
                <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_1859.jpg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
                </div>
                <div className="col-md-4">
                  <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_1855.jpg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
                </div>
            </div>
            <div className="row" style={{marginLeft:"150px",marginBottom:"25px"}}>
              <div className="col-md-4">
                <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_0071.jpeg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
              </div>
              <div className="col-md-4">
                <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_0081.jpeg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
                </div>
                <div className="col-md-4">
                  <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_1879.jpeg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
                </div>
            </div>
            <div className="row" style={{marginLeft:"150px",marginBottom:"25px"}}>
              <div className="col-md-4">
                <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_1936.jpeg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
              </div>
              <div className="col-md-4">
                <img
                        className="img-rounded img-responsive"
                        src={require("assets/img/clients/IMG_2068.jpeg").default}
                        alt="insta"
                        style={{width:"350px"}}
                    />
                </div>
                <div className="col-md-4">
                </div>
            </div>
            </div>
        <DemoFooter />
      </>
    );
  }
  
  export default GalleryPage;
  