import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import SectionCarousel from "views/prod/SectionCarousel.js";

function SectionInfo() {
  return (
    <>
      <Container className="tim-container">
        <Row>
            <Col>
            <div className="text-center">
              <h1>
                Welcome!<br></br><xsmall>If you love your natural hair or are transitioning to natural hair look no further.</xsmall>
              </h1>
            </div>
            </Col>
        </Row>
        <Row>
           
            <Col>
            <div className="owner">
                <div className="avatarAlign">
                {/*<img
                    alt="..."
                    className="img-circle img-no-padding img-responsive"
                    src={require("assets/img/profile_photo.jpg").default}
                />*/}
                </div>
                <div className="name">
                <h4 className="title">
                    Annie Streufert<br />
                </h4>
                <h6 className="description">Owner & Licensed Natural Hair Stylist</h6>
                <h7 className="text-muted">Phone/Text: <a href="tel:629-214-0106" style={{fontWeight:"400"}}>(629) 214-0106</a></h7><br></br>
            <h7 className="text-muted">E-mail: <a href="mailto:annie@embracenaturalhair.com" style={{fontWeight:"400"}}>annie@embracenaturalhair.com</a></h7>
                </div>

            </div></Col>
            {/*<Col>
            <div className="text-center">
              <h4>
                Services Availalble
              </h4>
            </div>
            <div>
            <p><h7>For Pricing and to book an appointment call, text or email.</h7></p>
              <ul>
              <li><strong>Braids</strong> - knotless, box braids and natural hair styles</li>
              <li><strong>Crochet</strong> - pre-looped hair required</li>
              <li><strong>Twists</strong></li>
              <li><strong>Natural Twists and Twist Outs</strong></li>
              <li><strong>Natural Hair Styles</strong></li>
<li><strong>Silk Press</strong></li>
<li><strong>Braid Removal</strong></li>
<li><strong>Loc Maintenance</strong> - Retouch (retwist or interlocking)</li>
<li>Consultation and services for Parents with Children that are adopted, foster parents</li>
</ul>
<h6>Questions on a style not listed please call (629) 214-0106</h6>
            </div>
            </Col>*/}
        </Row>
        <hr></hr>
        <Row>
        <Col>
            <div className="text-center">
              <h4 style={{fontWeight:"400"}}>
                Services Available (by appointment only)
              </h4>
            </div>
            <div>
            <p><h7 style={{fontWeight:"400"}}>For Pricing or to book an appointment call, text or e-mail.</h7></p>
              <ul>
              <li><strong>Consultation Services</strong></li>
              <li><strong>Children Styles</strong></li>
              <li><strong>Braids</strong> - knotless, box braids and natural hair styles</li>
              <li><strong>Crochet</strong> - pre-looped hair required</li>
              <li><strong>Twists</strong></li>
              <li><strong>Natural Hair Styles</strong></li>
                <li><strong>Braid Removal</strong></li>
                <li><strong>Loc Maintenance</strong> - retwist</li>
                </ul>
                <h6>Consultation and services for parents with foster children or adopted children.</h6><br></br>
                <h7 style={{fontWeight:"400"}}>Questions on a style not listed please call/text <a href="tel:6292140106" style={{fontWeight:"bold"}}>(629) 214-0106</a></h7>
            </div>
            </Col>
            <Col>
            <SectionCarousel/>
            </Col>
        </Row>
      </Container>
    </>
  );
}

export default SectionInfo;
