/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/OtherNavbar";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/FooterMaster";

function ProfilePage() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/profile_photo.jpg").default}
              />
            </div>
            <div className="name">
              <h4 className="title">
                Annie Streufert<br />
              </h4>
              <h6 className="description">Owner & Licensed Natural Hair Stylist</h6>
            </div>
          </div>
          <Row>
            {/*<Col className="ml-auto mr-auto text-center" md="6">*/}
            <Col>
              <p style={{fontWeight:"400"}}>
              <strong>About Me:</strong>  I am a Mom of 2 wonderful African American children who are adopted.  I went into this business after years of reading books, watching YouTube videos, going to Stylists, but still not quite understanding how exactly to do my children’s hair.  My passion for hair goes beyond just the styles.  I want to be able to also reach out to others and educate and motivate!  If you are a new parent and you need guidance on maintaining your child’s hair call me for a consultation.  If you are parent who has been doing your child’s hair on your own but are ready for more call me!  Adults are welcome as well!  My salon is in Nolensville, but I understand Nashville is large, give me a call, we will figure it out!


              </p>
              <br />
              {/*<Button className="btn-round" color="default" outline>
                <i className="fa fa-cog" /> Settings
  </Button>*/}
            </Col>
          </Row>
          <br />
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                  style={{fontWeight:"400"}}
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Services
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                  style={{fontWeight:"400"}}
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          {/* Tab panes */}
          <TabContent className="following" activeTab={activeTab}>
            <TabPane tabId="1" id="follows">
              <p><h6>For Pricing and to book an Appointment call, text or email.</h6></p>
              <ul>
              <li><strong>Consultation Services</strong></li>
              <li><strong>Children Styles</strong></li>
              <li><strong>Braids</strong> - knotless, box braids and natural hair styles</li>
              <li><strong>Crochet</strong> - pre-looped hair required</li>
              <li><strong>Twists</strong></li>
              <li><strong>Natural Hair Styles</strong></li>
                <li><strong>Braid Removal</strong></li>
                <li><strong>Loc Maintenance</strong> - retwist</li>
                </ul>
                <h6>Consultation and services for parents with foster children or adopted children.</h6><br></br>
                <h7 style={{fontWeight:"400"}}>Questions on a style not listed please call/text <a href="tel:629-214-0106" style={{fontWeight:"bold"}}>(629) 214-0106</a></h7>

              {/*<Row>
                <Col className="ml-auto mr-auto" md="6">
                  <ul className="list-unstyled follows">
                    <li>
                      <Row>
                        <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={
                              require("assets/img/faces/clem-onojeghuo-2.jpg")
                                .default
                            }
                          />
                        </Col>
                        <Col className="ml-auto mr-auto" lg="7" md="4" xs="4">
                          <h6>
                            Flume <br />
                            <small>Musical Producer</small>
                          </h6>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="3" md="4" xs="4">
                          <FormGroup check>
                            <Label check>
                              <Input
                                defaultChecked
                                defaultValue=""
                                type="checkbox"
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </li>
                    <hr />
                    <li>
                      <Row>
                        <Col className="mx-auto" lg="2" md="4" xs="4">
                          <img
                            alt="..."
                            className="img-circle img-no-padding img-responsive"
                            src={
                              require("assets/img/faces/ayo-ogunseinde-2.jpg")
                                .default
                            }
                          />
                        </Col>
                        <Col lg="7" md="4" xs="4">
                          <h6>
                            Banks <br />
                            <small>Singer</small>
                          </h6>
                        </Col>
                        <Col lg="3" md="4" xs="4">
                          <FormGroup check>
                            <Label check>
                              <Input defaultValue="" type="checkbox" />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </li>
                  </ul>
                </Col>
              </Row>*/}
            </TabPane>
            <TabPane className="text-center" tabId="2" id="following">
              <Row>
                <Col>
                <h6 className="text-muted">E-mail: <a href="mailto:annie@embracenaturalhair.com" style={{fontWeight:"bold"}}>annie@embracenaturalhair.com</a></h6>
                </Col>
                
              </Row>
              <Row>
              <Col>
                <h6 className="text-muted">Phone: <a href="tel:629-214-0106" style={{fontWeight:"bold"}}>(629) 214-0106</a></h6>
                </Col>
              </Row>
              <Row>
                <Col>
                <p><strong>Salon Address:</strong></p>
                <address>
                  EMBRACE NATURAL HAIR LOVE<br></br>
                  Lux Salons<br></br>
                  7180 Nolensville Road<br></br>
                  Nolensville, TN 37135<br></br>
                </address>
                </Col>
              </Row>
              
              
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default ProfilePage;
