import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function GalleryHeader() {
  return (
    <>
      <div
        className="page-header page-header-xxs"
      >

      </div>
    </>
  );
}

export default GalleryHeader;
